/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


@font-face {
    font-family: "Lato-Bold";
    src:
            local("Lato"),
            url(./assets/fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Lato-Light";
    src:
            local("Lato"),
            url(./assets/fonts/Lato-Light.ttf) format("truetype");
}
@font-face {
    font-family: "Lato-Regular";
    src:
            local("Lato"),
            url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}

ngx-mat-intl-tel-input {
    .ngx-mat-tel-input-container {
        button {
            opacity: 1 !important;
            span {
                display: flex;
                align-items: center;
            }
        }
        .country-selector-code {
            color: #1f2937 !important;
        }
    }
}
